import React from 'react';
import { Link } from "react-router-dom";
import {Icons} from './Icons'

const Site = () => {
  return (
    <div className="mt-8">
      <div className="flex m-2 bg-neutral-200 p-2 rounded-l">
        <div className="flex-auto">
          <h3 className="text-base">
            <Link
              to="/"
              className="font-bold"
            >billbolte.com</Link>
          </h3>
        </div>
        <div className="flex-auto text-right text-sm">September 2023</div>
      </div>
      <div className="m-5">
        <h4 className="mt-2 font-bold">This site was created using:</h4>
        <div className="flex flex-row flex-wrap mt-2">
          <Icons
            iconSet="site"
           />
        </div>
      </div>
    </div>
  )
}

export default Site;
