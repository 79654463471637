import React from 'react';
import { Outlet } from "react-router-dom";
import Nav from '../components/Nav';
import Footer from '../components/Footer'

const Layout = () => {
  return (
    <div>
      <Nav />
      <hr />
      <div className="content-start space-y-4 mt-8 mx-5 lg:mb-5 md:mb-20 lg:pb-2 md:pb-5 sm:pb-10 pb-10">
        <Outlet />
      </div>
      <Footer />
    </div>
  );
};

export default Layout;
