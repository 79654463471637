import React from 'react';
import { BookOpenIcon } from '@heroicons/react/24/solid';
import { Link } from "react-router-dom";

const Education = () => {
  return (
    <div className="mt-8 border-t-2 border-neutral-300">
      <h2 className="text-lg font-bold my-3">Education</h2>
      <div className="flex m-2 bg-neutral-200 p-2 rounded-l">
        <div className="flex-auto">
          <h3 className="text-base">
            <Link
              to="https://www.sckans.edu/"
              target="_blank"
              rel="noopener noreferrer"
              className="font-bold"
            >Southwestern College</Link>, Winfield, KS
          </h3>
        </div>
      </div>
      <div className="flex m-2 p-2 rounded-l">
        <div className="flex-auto">
          <h3 className="text-base">
            <BookOpenIcon className="text-blue-700 w-6 float-left mr-2" />
            B.S Computer Programming Technology
          </h3>
        </div>
        <div className="flex-auto text-right text-sm">December 2003</div>
      </div>
    </div>
  )
}

export default Education;
