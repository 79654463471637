import React from 'react';
import {Icons} from './Icons'

const Appointment = () => {
  return (
    <div className="mt-8">
      <div className="flex m-2 bg-neutral-200 p-2 rounded-l">
        <div className="flex-auto">
          <h3 className="text-base">
            <span className="font-bold">Appointment Launch</span>: Ruby on Rails Developer (contract)
          </h3>
        </div>
        <div className="flex-auto text-right text-sm">January 2024 – March 2024</div>
      </div>
      <div className="m-5">
        <p>
          <i>The primary task of this contract was to convert an older Rails code base to Rails 7 for their Optical Launch website. Along with that, new features were added and optimization was implemented on the PostgreSQL database.</i>
          <br /><br/>
          <b>Site:</b> <a href="https://www.opticallaunch.com/" className="underline underline-offset-4 decoration-blue-400">
            Optical Launch
          </a> 
        </p>
        <h4 className="mt-2 font-bold">Technologies:</h4>
        <div className="flex flex-row flex-wrap mt-2">
          <Icons
            iconSet="ap"
          />
        </div>
      </div>
    </div>
  )
}

export default Appointment;
