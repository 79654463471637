import React from 'react';
import { Link } from "react-router-dom";

const Contact = () => {
  // const emAddr = '&#x62;&#x69;&#x6c;&#x6c;&#x2e;&#x62;&#x6f;&#x6c;&#x74;&#x65;&#x40;&#x67;&#x6d;&#x61;&#x69;&#x6c;&#x2e;&#x63;&#x6f;&#x6d;';
  const emAddr = 'bill.bolte@gmail.com';
  return (
    <>
      <h1 className="text-xl font-bold">How to Contact Me</h1>
      <div className="content-start space-y-4">
        <p>
          The easiest way to contact me is <Link
          className="font-bold"
          to={`mailto:${emAddr}`}>through email</Link>
        </p>
        <p>
          You can also contact me through my <Link
          to="https://www.linkedin.com/in/billbolte/"
          target="_blank"
          rel="noopener noreferrer"
          className="font-bold"
        >LinkedIn profile</Link>
        </p>
        <p>
          <Link
            to="/files/Bill-Bolte-Resume.pdf"
            target="_blank"
            className="font-bold"
            download>Download my resume</Link>
        </p>
      </div>
    </>
  );
};

export default Contact;
