import React from 'react';
import './App.css';
import { Routes, Route } from "react-router-dom";
import Layout from './pages/Layout'
import Home from './pages/Home'
import Career from './pages/Career'
import Contact from './pages/Contact'
import Error from './pages/Error'

const App = () => {
  return (
    <div className="App container mx-auto px-4 lg:max-w-screen-lg md:max-w-screen-lg">
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="career" element={<Career />} />
          <Route path="contact" element={<Contact />} />
          <Route path="*" element={<Error />} />
        </Route>
      </Routes>
    </div>
  );
}

export default App;
