import React from 'react';
import {Icons} from './Icons'
import { Link } from "react-router-dom";

const Worktorch = () => {
  return (
    <div className="mt-8">
      <div className="flex m-2 bg-neutral-200 p-2 rounded-l">
        <div className="flex-auto">
          <h3 className="text-base">
            <Link
              to="https://worktorch.io/"
              target="_blank"
              rel="noopener noreferrer"
              className="font-bold"
            >WorkTorch</Link>: Senior Software Engineer
          </h3>
        </div>
        <div className="flex-auto text-right text-sm">December 2021 – August 2023</div>
      </div>
      <div className="m-5">
        <p>
          <i>WorkTorch is a career discovery platform with a strong focus on employee retention. I focused on developing
            WorkTorch's web applications, covering both back-end (Node.js) and front-end (React). A standout project
            involved a deep dive into the WorkTorch data layer, resulting in enhanced performance through schema
            optimization. This led to improvements in both the database and GraphQL API. Additionally, I created and
            developed KPI-based reports to monitor WorkTorch application usage and growth. I was also tasked with
            connecting to remote API services to interface with various HRIS applications.</i>
          <br/><br/>
          <b>Site:</b> <a href="https://worktorch.io/" className="underline underline-offset-4 decoration-blue-400">
          WorkTorch
        </a>
        </p>
        <h4 className="mt-2 font-bold">Technologies:</h4>
        <div className="flex flex-row flex-wrap mt-2">
        <Icons
            iconSet="wt"
          />
        </div>
      </div>
    </div>
  )
}

export default Worktorch;
