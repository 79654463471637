import React from 'react';
import {
  GitPlain, ReactOriginal, TypescriptOriginal,
  TailwindcssPlain, AppleOriginal,
  GithubOriginal, WebstormOriginal, DigitaloceanOriginal,
  MysqlOriginal, PostgresqlPlain, RailsPlain,
  PhpPlain, CodeigniterPlain,
  IonicOriginal, AngularjsOriginal,
  JqueryOriginal, GitlabOriginal,
  BootstrapPlain, UbuntuPlain,
  ApacheLine, AmazonwebservicesOriginal,
  DockerOriginal, VscodeOriginal,
  GraphqlPlain, JestPlain,
  JiraPlain, MaterialuiOriginal,
  NodejsPlain, SequelizePlain, BitbucketOriginal
} from "devicons-react";

interface IIcon {
  icon: JSX.Element,
  tooltip: string
}
interface ISet {
  icons: IIcon[]
}

type ISets = {
  [a: string]: ISet
}

export const Icons = ({iconSet}: {iconSet: string}) => {

  const iconSets: ISets = {
    site: {
      icons: [
        {
          icon: <ReactOriginal size="40" />,
          tooltip: "Typescript"
        },
        {
          icon: <TypescriptOriginal size="40" />,
          tooltip: "React"
        },
        {
          icon: <TailwindcssPlain size="40" />,
          tooltip: "React"
        },
        {
          icon: <GitPlain size="40" />,
          tooltip: "React"
        },
        {
          icon: <GithubOriginal size="40" />,
          tooltip: "React"
        },
        {
          icon: <WebstormOriginal size="40" />,
          tooltip: "React"
        },
        {
          icon: <AppleOriginal size="37" />,
          tooltip: "MacOS"
        },
      ]
    },
    vo: {
      icons: [
        {
          icon: <RailsPlain size="40" />,
          tooltip: "RoR"
        },
        {
          icon: <PostgresqlPlain size="40" />,
          tooltip: "PostgreSQL"
        },
        {
          icon: <GitPlain size="40" />,
          tooltip: "Git"
        },
        {
          icon: <BitbucketOriginal size="40" />,
          tooltip: "Bitbucket"
        },
        {
          icon: <DigitaloceanOriginal size="50" />,
          tooltip: "Digital Ocean"
        },
        {
          icon: <UbuntuPlain size="42" />,
          tooltip: "Ubuntu"
        },
        {
          icon: <BootstrapPlain size="40" />,
          tooltip: "Bootstrap"
        },
        {
          icon: <VscodeOriginal size="38" />,
          tooltip: "VS Code"
        },
      ]
    },
    ap: {
      icons: [
        {
          icon: <RailsPlain size="40" />,
          tooltip: "RoR"
        },
        {
          icon: <PostgresqlPlain size="40" />,
          tooltip: "PostgreSQL"
        },
        {
          icon: <GitPlain size="40" />,
          tooltip: "Git"
        },
        {
          icon: <BootstrapPlain size="40" />,
          tooltip: "Bootstrap"
        },
        {
          icon: <JqueryOriginal size="40" />,
          tooltip: "Jquery"
        },
        {
          icon: <VscodeOriginal size="38" />,
          tooltip: "VS Code"
        },
      ]
    },
    ht: {
      icons: [
        {
          icon: <PhpPlain size="50" />,
          tooltip: "PHP"
        },
        {
          icon: <CodeigniterPlain size="40" />,
          tooltip: "CodeIgniter"
        },
        {
          icon: <RailsPlain size="40" />,
          tooltip: "RoR"
        },
        {
          icon: <MysqlOriginal size="40" />,
          tooltip: "MySQL"
        },
        {
          icon: <PostgresqlPlain size="40" />,
          tooltip: "PostgreSQL"
        },
        {
          icon: <GitPlain size="40" />,
          tooltip: "Git"
        },
        {
          icon: <GitlabOriginal size="40" />,
          tooltip: "Gitlab"
        },
        {
          icon: <IonicOriginal size="40" />,
          tooltip: "Ionic"
        },
        {
          icon: <AngularjsOriginal size="40" />,
          tooltip: "Angularjs"
        },
        {
          icon: <JqueryOriginal size="40" />,
          tooltip: "Jquery"
        },
        {
          icon: <BootstrapPlain size="40" />,
          tooltip: "Bootstrap"
        },
        {
          icon: <DigitaloceanOriginal size="50" />,
          tooltip: "Digital Ocean"
        },
        {
          icon: <UbuntuPlain size="42" />,
          tooltip: "Ubuntu"
        },
        {
          icon: <ApacheLine size="60" />,
          tooltip: "Apache"
        },
        {
          icon: <AppleOriginal size="37" />,
          tooltip: "MacOS"
        }
      ]
    },
    wt: {
      icons: [
        {
          icon: <NodejsPlain size="40" />,
          tooltip: "Nodejs"
        },
        {
          icon: <ReactOriginal size="40" />,
          tooltip: "React"
        },
        {
          icon: <TypescriptOriginal size="40" />,
          tooltip: "Typescript"
        },
        {
          icon: <PostgresqlPlain size="40" />,
          tooltip: "PostgreSQL"
        },
        {
          icon: <GraphqlPlain size="40" />,
          tooltip: "GraphQL"
        },
        {
          icon: <SequelizePlain size="40" />,
          tooltip: "Sequelize"
        },
        {
          icon: <DockerOriginal size="50" />,
          tooltip: "Docker"
        },
        {
          icon: <GitPlain size="40" />,
          tooltip: "Git"
        },
        {
          icon: <GitlabOriginal size="40" />,
          tooltip: "Gitlab"
        },
        {
          icon: <JiraPlain size="40" />,
          tooltip: "Jira"
        },
        {
          icon: <AmazonwebservicesOriginal size="40" />,
          tooltip: "AWS"
        },
        {
          icon: <MaterialuiOriginal size="40" />,
          tooltip: "Material UI"
        },
        {
          icon: <JestPlain size="35" />,
          tooltip: "Jest"
        },
        {
          icon: <WebstormOriginal size="40" />,
          tooltip: "Webstorm"
        },
        {
          icon: <AppleOriginal size="37" />,
          tooltip: "MacOS"
        }
      ]
    }
  };

  const renderIcons = (set: ISet) => (
    <div>
        {set.icons.map(({icon, tooltip}) => (
          <div className="m-2 tooltip">
            {icon}
            <span className="tooltiptext">{tooltip}</span>
          </div>
        ))}
    </div>
  )

  return (
    <div>
      { renderIcons(iconSets[iconSet]) }
    </div>
  )
}
