import { Link } from "react-router-dom";
import React from "react";
import {
  LinkedinOriginal
} from "devicons-react";

const Footer = () => {
  return (
    <footer className="fixed bottom-0 left-0 z-20 w-full p-4 md:p-6 bg-neutral-400 text-white">
      <div className="flex justify-between text-sm text-white lg:max-w-screen-lg md:max-w-screen-lg">
        <div className="flex-auto text-white">
          © 2023 <Link to="/" className="block mt-3 lg:inline-block lg:mt-0 text-white mr-1">billbolte.com.</Link> All Rights Reserved.
        </div>
        <div className="flex-auto text-white-200 text-right text-sm ">
          Follow me on&nbsp;
          <div className="text-right w-10 float-right">
            <Link
              to="https://www.linkedin.com/in/billbolte/"
              target="_blank"
              rel="noopener noreferrer"
            ><LinkedinOriginal size="20" /></Link>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
