import React from 'react';
import {Icons} from './Icons'

const Visibility = () => {
  return (
    <div className="mt-8">
      <div className="flex m-2 bg-neutral-200 p-2 rounded-l">
        <div className="flex-auto">
          <h3 className="text-base">
            <span className="font-bold">Visibility Optics LLC</span>: Ruby on Rails Developer (contract)
          </h3>
        </div>
        <div className="flex-auto text-right text-sm">June 2017 – October 2017</div>
      </div>
      <div className="m-5">
        <p><i>Visibility Optics (now Appointment Launch) creates a truly unique eyewear buying and patient education experience through the use of computer vision and patient specific eye care data. I wrote Rails code for their web application.</i></p>
        <h4 className="mt-2 font-bold">Technologies:</h4>
        <div className="flex flex-row flex-wrap mt-2">
          <Icons
            iconSet="vo"
          />
        </div>
      </div>
    </div>
  )
}

export default Visibility;
