import React from 'react';

const Home = () => {
  return (
    <>
      <h1 className="text-xl font-bold">Bill Bolte, Developer on the Plains</h1>
      <div className="content-start space-y-4 lg:mb-7 md:mb-20 lg:pb-10 md:pb-10 sm:pb-10 pb-10">
        <img src="./images/me.jpg" className="rounded-lg w-60 shadow-xl shadow-gray-400 rotate-6 sm:float-right m-6" alt="Bill Bolte" />
        <p>I am an applications developer with over two decades of experience across web and desktop applications. Throughout my career, I have successfully delivered custom solutions to a diverse client base, including corporate, charitable, and public sector organizations. I am committed to staying at the forefront of emerging technologies, open source projects, and industry trends.</p>
        <p>Through the years, I've worked with many tech stacks including: PHP, JavaScript, Ruby on Rails, Node.js, React.js, MySQL, PostgreSQL, LAMP, Ionic with Angular and many others. I've managed a team of developers and mentored junior programmers. I have a Scrum Master certificate and have worked in Agile environments.</p>
        <h2 className="font-bold text-lg">A little background...</h2>
        <p>I started life as a graphic designer. Getting involved in the internet in the 90's changed my trajectory in a big way. Designing web sites eventually led me to cobbling together an Ecommerce store. It was magic and I was hooked. I went back to school to explore it with greater depth. This was the beginning of my quest into technology and how the second part of my life began.</p>
        <h2 className="font-bold text-lg">Not only that...</h2>
        <p>I'm also a believer, a husband, a father and will brag that mine is the greatest family ever! I love the outdoors and when not coding, will spend as much time as I can outside. I'm frequently found on my bicycle riding the nearby rail trail or the singletrack. Hiking, camping and fishing are a favorite pastime. One of my passions in life is trout fishing in northern New Mexico and try to get back there frequently. I'm also a licensed ham radio operator (callsign: KEOJTI).</p>
        <p>I love to cook and try new recipes or continue to perfect favorites. In my opinion, one of the great pleasures in life is a perfectly grilled steak. Many a weekend, I can be found in my backyard hovering over the grill or manning the smoker or deep frying a turkey. Low country boil anyone?</p>
        <p className="font-bold mb-4">I also love coffee... way, way too much.</p>
        <div className="lg:mb-8 md:mb-20 lg:pb-2 md:pb-7 sm:pb-10 pb-10 m-10"></div>
      </div>
    </>
  );
};

export default Home;
