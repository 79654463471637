import { Link } from "react-router-dom";
import React, { useState } from "react";
import { HomeModernIcon } from '@heroicons/react/24/solid'

const Nav = () => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <nav className="flex items-center justify-between flex-wrap p-4 bg-neutral-300 rounded-lg">
      <Link to="/">
        <h1 className="w-100 mr-6 lg:mr-72">
          <HomeModernIcon className="text-black w-6 float-left mr-2" />
          billbolte.com
        </h1>
      </Link>
      <div className="block lg:hidden">
        <button
          onClick={() => setIsOpen(!isOpen)}
          className="flex items-center px-3 py-2 rounded text-black-500 hover:text-black-400"
        >
          <svg
            className={`fill-current h-3 w-3 ${isOpen ? "hidden" : "block"}`}
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
          </svg>
          <svg
            className={`fill-current h-3 w-3 ${isOpen ? "block" : "hidden"}`}
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M10 8.586L2.929 1.515 1.515 2.929 8.586 10l-7.071 7.071 1.414 1.414L10 11.414l7.071 7.071 1.414-1.414L11.414 10l7.071-7.071-1.414-1.414L10 8.586z" />
          </svg>
        </button>
      </div>
      <div
        className={`w-full block lg:flex lg:items-center lg:w-auto ${isOpen ? "block" : "hidden"} justify-items-end`}
      >
        <div className="text-sm justify-items-end">
          <Link to="/" className="block mt-3 lg:inline-block lg:mt-0 text-white-200 mr-6">Home</Link>
          <Link to="/career" className="block mt-3 lg:inline-block lg:mt-0 text-white-200 mr-6">Career &amp; Experience</Link>
          <Link to="/contact" className="block mt-3 lg:inline-block lg:mt-0 text-white-200">Contact me</Link>
        </div>
      </div>
    </nav>
  );
};

export default Nav;
