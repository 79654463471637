import React from 'react';
import Worktorch from '../components/career/Worktorch'
import Visibility from '../components/career/Visibility'
import Hightouch from '../components/career/Hightouch'
import Site from '../components/career/Site'
import Appointment from '../components/career/Appointment'
import Education from '../components/education/education'
import { Link } from "react-router-dom";

const Career = () => {
  return (
    <>
      <div className="flex">
        <div className="flex-auto mr-4">
          <h1 className="text-xl font-bold">Places I've Worked &amp; Things I've Done</h1>
          <p className="mt-4">I've been privileged to work at several great companies and have been able to be a part of many interesting and challenging projects. I've sat side by side with some great people and learned much from them all. I hope I've been able to give back to them as well.</p>

          <p className="mt-4">You can <Link
              to="/files/Bill-Bolte-Resume.pdf"
              target="_blank"
              className="font-bold"
              download>download my resume</Link> here
          </p>
        </div>
      </div>

      <div className="content-start mb-56 max-h-full ">
        <Appointment />
        <Worktorch />
        <Visibility />
        <Hightouch />
        <Site />
        <Education />

        <div className="mb-20">&nbsp;</div>
      </div>
    </>
  );
}

export default Career;
