import React from 'react';
import {Icons} from './Icons'
import { Link } from "react-router-dom";

const Hightouch = () => {
  return (
    <div className="mt-8">
      <div className="flex m-2 bg-neutral-200 p-2 rounded-lg">
        <div className="flex-auto">
          <h3 className="text-base">
            <Link
              to="https://hightouchtechnologies.com/"
              target="_blank"
              rel="noopener noreferrer"
              className="font-bold"
            >High Touch, Inc.</Link>: Software Development Supervisor
          </h3>
        </div>
        <div className="flex-auto text-right text-sm">February 2006 – November 2021</div>
      </div>
      <div className="m-5">
        <p><i>High Touch, Inc. is a technology firm offering software, hardware and network services to the Rent-to-own and restaurant industries. I managed a team of developers that focussed on web and mobile projects around the Rent-to-own sphere. Notable projects I worked on: Rent-to-own customer portal using PHP, CodeIgniter and MySQL; POS System API layer in Ruby on Rails and PostgreSQL; Various mobile apps using Ionic and Angular. These interfaced with the aforementioned API layer. Other positions held: Senior Web Developer and Senior Software Developer.</i></p>
        <h4 className="mt-2 font-bold">Technologies:</h4>
        <div className="flex flex-row flex-wrap mt-2">
          <Icons
            iconSet="ht"
          />
        </div>
      </div>
    </div>
  )
}

export default Hightouch;
